import { render, staticRenderFns } from "./NavbarDropdown.vue?vue&type=template&id=28b15867&scoped=true&"
import script from "./NavbarDropdown.vue?vue&type=script&lang=js&"
export * from "./NavbarDropdown.vue?vue&type=script&lang=js&"
import style0 from "./NavbarDropdown.vue?vue&type=style&index=0&id=28b15867&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b15867",
  null
  
)

export default component.exports