<template>
  <div class="navbar-dropdown">
    <ul class="dropdown-wrapper">
      <li v-for="(item, i) in items" :key="i">
        <router-link :to="item.link">
          <span 
            :class="item.titleKey == 'globals.products.bit5.title' || item.titleKey == 'globals.products.bit20.title' ? 'greyTitle' : 'title'">
            {{ $t(item.titleKey) }}
          </span>
          <span v-if="item.descriptionKey" 
            :class="item.titleKey == 'globals.products.bit5.title' || item.titleKey == 'globals.products.bit20.title' ? 'greyDescription' : 'description'">
            {{ $t(item.descriptionKey) }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavbarDropdown",
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {
  },
  methods: {
  }
}
</script>
 
<style lang='scss' scoped>
.dropdown-wrapper {
  list-style-type: none;
  margin: 0;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  row-gap: 35px;
  box-shadow: $navbar-dropdown-shadow;
  border-radius: $navbar-dropdown-border-radius;
  background: $navbar-dropdown-linear-gradient;
}

.title {
  display: block;
  font-size: 18px;
  line-height: 30px;
  font-family: "HKNova-Bold", sans-serif;

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    display: inline-block;
    margin: 0 10px 0 0;
    background-color: $primary-purple;
    border-radius: 100%;
  }
}

.greyTitle {
  display: block;
  font-size: 18px;
  line-height: 30px;
  font-family: "HKNova-Bold", sans-serif;
  color: grey;

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    display: inline-block;
    margin: 0 10px 0 0;
    background-color: grey;
    border-radius: 100%;
  }
}

.description {
  font-family: "HKNova", sans-serif;
  color: $text-blue;
  display: block;
  min-width: 165px;
  line-height: 20px;
  font-size: 12px;
}

.greyDescription {
  font-family: "HKNova", sans-serif;
  color: grey;
  display: block;
  min-width: 165px;
  line-height: 20px;
  font-size: 12px;
}

</style>